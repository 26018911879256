import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/layout"

const Entrust = () =>
    import ('@/views/Entrust.vue')
const Invite = () =>
    import ('@/views/Invite.vue')
const Login = () =>
    import ('@/views/user/Login.vue')
const MaterialStore = () =>
    import ('@/views/Material/MaterialStore.vue')
const SendMore = () =>
    import ('@/views/SendMore2.vue')
    // import ('@/views/SendMore.vue')



Vue.use(VueRouter)
const entrustRouter = {
    path: '/robot', //托管
    name: 'robot',
    component: Layout,
    meta: {
        title: '小助手管理',
        icon: 'code'
    },
    children: [{
        path: '/', //邀请托管
        name: 'Invite',
        component: Invite,
        meta: {
            title: '企业成员',
            icon: 'team'
        }
    }, {
        path: 'entrust', //托管管理
        name: 'Entrust',
        component: Entrust,
        meta: {
            title: '托管管理',
            icon: 'setting'
        }
    }, ]

}

const materialRouter = {
    path: '/material', //托管
    name: 'material',
    component: Layout,
    meta: {
        title: '素材管理',
        icon: 'hdd'
    },
    children: [{
        path: '/', //托管管理
        name: 'materialstore',
        component: MaterialStore,
        meta: {
            title: '素材库',
            icon: 'database'
        }
    }]
}
const SendMoreRouter = {
    path: '/sendmore', //群发
    name: 'sendmore',
    component: Layout,
    meta: {
        title: '群发',
        icon: 'share-alt',
        hidden: true
    },
    children: [{
        path: '/',
        name: '',
        component: SendMore
    }]
}

const AutomaticRouter = {
    path: '/automatic', //自动回复
    name: 'automatic',
    component: Layout,
    meta: {
        title: '自动回复',
        icon: 'message',
        hidden: true
    },
    children: [{
        path: '/',
        name: '',
        component: () =>
            import ('@/views/Automatic.vue')
    }]
}
const ExpandRouter = {
    path: '/expand', //拓客
    name: 'expand',
    component: Layout,
    meta: {
        title: '拓客',
        icon: 'user-add',
        hidden: true
    },
    children: [{
        path: '/',
        name: '',
        component: () =>
            import ('@/views/ExpandCustomer.vue')
    }]
}
const ManageRouter = {
    path: '/manage', //管理
    name: 'manage',
    component: Layout,
    meta: {
        title: '管理',
        icon: 'appstore',
    },
    children: [
        // {
        //     path: 'company',
        //     name: 'company',
        //     meta: {
        //         title: '企业',
        //         icon: 'bank',
        //     },
        //     component: () =>
        //         import ('@/views/Manage/Company.vue')
        // },
        // {
        //     path: 'user',
        //     name: 'user',
        //     meta: {
        //         title: '用户',
        //         icon: 'user',
        //     },
        //     component: () =>
        //         import ('@/views/Manage/User.vue')
        // },
        {
            path: 'label',
            name: 'label',
            meta: {
                title: '标签管理',
                icon: 'tags',
            },
            component: () =>
                import ('@/views/Manage/Label.vue')
        },
        {
            path: 'customer',
            name: 'customer',
            meta: {
                title: '客户管理',
                icon: 'contacts',
            },
            component: () =>
                import ('@/views/Manage/CustomerManage.vue')
        }
    ]
}
const SettingRouter = {
    path: '/setting', //设置
    name: 'setting',
    component: Layout,
    meta: {
        title: '公司设置',
        icon: 'setting',
        hidden: true
    },
    children: [{
        path: '/',
        name: '',
        component: () =>
            import ('@/views/Setting')
    }]
}
const LiveRouter = {
    path: '/live', //直播
    name: 'live',
    component: Layout,
    meta: {
        title: '直播设置',
        icon: 'play-square',
        hidden: true
    },
    children: [{
        path: '/',
        name: '',
        component: () =>
            import ('@/views/Setting/LiveSetting.vue')
    }]
}
const multRouter = {
    path: '/mult', //群管理
    name: 'mult',
    component: Layout,
    meta: {
        title: '群管理',
        icon: 'project',
        hidden: true
    },
    children: [{
        path: '/',
        name: '',
        component: () =>
            import ('@/views/Market/MultCode.vue')
    }]
}
const DashRouter = {
    path: '/dash', //报表
    name: 'dash',
    component: Layout,
    meta: {
        title: '报表',
        icon: 'bar-chart',
        hidden: true
    },
    children: [{
        path: '/',
        name: '',
        component: () =>
            import ('@/views/Dash')
    }]
}
const MarketRouter = {
    path: '/market', //营销
    name: 'market',
    component: Layout,
    meta: {
        title: '营销',
        icon: 'gift',
    },
    children: [{
            path: 'channel',
            name: 'channel',
            meta: {
                title: '渠道码',
                icon: 'profile',
            },
            component: () =>
                import ('@/views/Market/ChannelCode.vue')
        },
        // {
        //     path: 'moments',
        //     name: 'moments',
        //     meta: {
        //         title: '朋友圈',
        //         icon: 'team',
        //     },
        //     component: () =>
        //         import ('@/views/Market/Moments.vue')
        // },
        {
            path: 'gift',
            name: 'gift',
            meta: {
                title: '邀请有礼',
                icon: 'gift',
            },
            component: () =>
                import ('@/views/Market/InviteGift.vue')
        }
    ]
}

const RiskRouter = {
    path: '/risk', //风控
    name: 'risk',
    component: Layout,
    meta: {
        title: '风控',
        icon: 'safety-certificate',
    },
    children: [{
            path: 'sensitive',
            name: 'sensitive',
            meta: {
                title: '敏感词',
                icon: 'security-scan',
            },
            component: () =>
                import ('@/views/Risk/Sensitive.vue')
        },
        {
            path: 'log',
            name: 'log',
            meta: {
                title: '敏感词报警日志',
                icon: 'file-exclamation',
            },
            component: () =>
                import ('@/views/Risk/Log.vue')
        },
        {
            path: 'heartbeat', //心跳
            name: 'Heartbeat',
            component: () =>
                import ('@/views/Risk/Heartbeat.vue'),
            meta: {
                title: '服务器监测',
                icon: 'dashboard'
            }
        },
    ]
}

const shopRouter = {
    path: '/shop', //电商
    name: 'shop',
    component: Layout,
    meta: {
        title: '电商',
        icon: 'shop',
    },
    children: [{
            path: 'product',
            name: 'product',
            meta: {
                title: '商品',
                icon: 'skin',
            },
            component: () =>
                import ('@/views/Shop/Product.vue')
        },
        {
            path: 'order',
            name: 'order',
            meta: {
                title: '订单',
                icon: 'account-book',
            },
            component: () =>
                import ('@/views/Shop/Order.vue')
        }
    ]
}

const routes = [{
        path: '/login',
        component: Login,
    },
    {
        path: '/',
        redirect: '/login'
    },
    entrustRouter,
    materialRouter,
    SendMoreRouter,
    AutomaticRouter,
    ExpandRouter,
    SettingRouter,
    LiveRouter,
    multRouter,
    DashRouter,
    ManageRouter,
    RiskRouter,
    MarketRouter,
    shopRouter
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
//
export const Menus = [
    entrustRouter,
    materialRouter,
    SendMoreRouter,
    AutomaticRouter,
    ExpandRouter,
    SettingRouter,
    LiveRouter,
    multRouter,
    DashRouter,
    ManageRouter,
    RiskRouter,
    MarketRouter
    //shopRouter
]