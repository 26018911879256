import MultChoose from './MultChoose'
import CustomerChoose from './CustomerChoose'
import RobotSelected from './RobotSelected'
const components = [
    MultChoose,
    CustomerChoose,
    RobotSelected
]
export default {
    install(Vue) {
        components.map(component => {
            if (component && component.install) {
                component.install(Vue)
            } else if (component) {
                Vue.component(component.name, component)
            }
        })
    }
}