<template>
    <div class="page">
        <a-form :form="searchForm"
            layout="inline" style="margin-bottom:20px">
            <a-form-item label="标签" >
                <a-select mode="multiple" v-model="searchForm.label_id" style="width: 200px">
                    <a-select-opt-group v-for="item in tagTree" :key="item.id">
                        <span slot="label"><a-icon type="dash" />{{item.name}}</span>
                        <a-select-option v-for="obj in item.children" :key="obj.id" :value="obj.id">
                            {{obj.name}}
                        </a-select-option>
                    </a-select-opt-group>
                </a-select>
            </a-form-item>
            <a-form-item >
            <a-button type="primary"
                        icon="search"
                        @click="handleClickSearch">
                搜索
            </a-button>
            </a-form-item>
        </a-form>
        <a-table 
            :columns="customerColumns" 
            :data-source="customerData"
            :row-key="record => record.friend_user_id"
            :pagination="pagination"
            size="small"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange ,getCheckboxProps:getCheckboxProps}"
            :bordered="false"
            @change="handleTableChange"
        >
            <template slot="tag" slot-scope="arr">
                <span v-for="item in arr" :key="item">
                    <a-tag color="blue" v-if="formatTag(item)">{{formatTag(item)}}</a-tag>
                </span>
                
            </template>
            <div class="account-view" slot="username" slot-scope="text,record">
                <a-avatar :src="record.avatar" />
                <div class="text">
                    {{record.username}}
                </div>
            </div>
        </a-table>
    </div>
</template>
<script>
const customerColumns = [
    {
        title: '客户',
        dataIndex: 'username',
        align:'center',
        scopedSlots: { customRender: 'username' },
    },
    {
        title: '个人标签',
        dataIndex: 'label',
        align:'center',
        scopedSlots: { customRender: 'tag' },
    }
]
import {
    mapState
  } from 'vuex'
export default {
    name:'customer-choose',
     props:{
        init:Boolean,
        selectedValue:Array
    },
    data(){
        return{
            searchForm:{label_id:[]},
            customerColumns,
            customerData:[],
            pagination: {
                total: 1,
                current: 1,
                pageSize: 10, //每页中显示10条数据
                showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
            },
            selectdList:[],
            selectedRowKeys:[],
        }
    },
    computed: {
        ...mapState(['tagList','tagTree'])
    },
    mounted(){
        this.handleClickSearch()
    },
    watch:{
        init(val){
            if(val){
                this.searchMultForm = {
                    label_id:[]
                }
                this.selectdList = []
                this.selectedRowKeys = []
                this.handleClickSearch()
            }
        }
    },
    methods:{
        handleTableChange(pagination){
            this.pagination = pagination
            this.handleSearch()
        },
        handleClickSearch(){
            this.pagination.current = 1
            this.handleSearch()
        },
        handleSearch(){
            let payload = {
                msgId:'searchFriendsByPage',
                data:{
                    pageSize:this.pagination.pageSize,
                    pageNum:this.pagination.current
                }
            }
            let {label_id} = this.searchForm
            if(label_id.length>0){
                payload.data.label_id = label_id
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/searchFriends",
                payload,
                },
                  (topic, data) => {
                    //   console.log(data)
                    this.customerData = data.res
                    this.pagination.total = data.count
                },
                this
            );
        },
        formatTag(id){
            let obj = this.tagList.filter(item=>item.label_id == id)
            return obj[0]?obj[0].label_name:''
        },
        onSelectChange(selectedRowKeys){
            // console.log(selectedRowKeys,selectedRows)
            this.selectedRowKeys = selectedRowKeys;
            // let empty = this.selectdList.concat(selectedRows)
            // let obj = {}
            // empty = empty.reduce((cur,next)=>{
            //     obj[next.friend_user_id] ? "" : obj[next.friend_user_id] = true && cur.push(next);
            //     return cur;
            // },[])
            
            // empty = empty.filter(item=>{
            //     return this.selectedRowKeys.includes(item.friend_user_id)
            // })
            // this.selectdList = empty
            this.$emit('handleSelected',selectedRowKeys)
        
        },
        getCheckboxProps(record){
            return{props: {
                // disabled: record.status === 1, // Column configuration not to be checked
                status: record.status,
            }}
        },
    }
}
</script>
<style lang="scss" scoped>
.page{
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
}
</style>