import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        mqttInit: false,
        getAgain: false,
        company: '',
        companyName: '',
        tagList: [],
        tagTree: [],
        robots: [],
        user: {
            _id: '',
            account: '',
            password: ''
        }
    },
    mutations: {
        syncMqttConnect(state, payload) {
            state.mqttInit = payload
        },
        syncUpdateAgain(state, payload) {
            state.getAgain = payload
        },
        syncUpdateCompany(state, payload) {
            state.company = payload
        },
        syncUpdateCompanyName(state, payload) {
            state.companyName = payload
        },
        syncUpdateUser(state, payload) {
            state.user = payload
        },
        syncUpdateTag(state, payload) {
            state.tagList = payload.tagList
            state.tagTree = payload.tagTree
        },
        syncUpdateRobots(state, payload) {
            state.robots = payload
        }
    },
    actions: {},
    modules: {}
})