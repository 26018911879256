import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import Lockr from 'lockr';
import 'ant-design-vue/dist/antd.css';
import "./assets/iconfont/iconfont.css";
import * as filters from '@/filters' // 全局filter
import './assets/chat.css'
import ChatView from './assets/chat.umd.min'
import BaseComponents from '@/components'
Vue.prototype.$chat = ChatView.chat
Vue.use(BaseComponents)
window.lockr = Lockr
    // 注册全局filter
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

//全局守卫
router.beforeEach((to, from, next) => {
    // //这里是对登录后的值进行判断，也可对token的值进行判断
    // console.log(to, from)
    if (to.path == '/login') {
        next()
    } else {
        if (from.path === "/" && to.path !== '/login') {
            router.push("/login")
        } else {
            next()
        }

        //     if (token) {
        //         next()
        //     } else {
        //         router.push("/login");
        //     }
    }
});

Vue.config.productionTip = false
Vue.use(Antd)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')