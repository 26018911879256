<template>
  <div class="page-main-div">
    <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider theme="light" v-model="collapsed" :trigger="null" collapsible>
      <div class="logo" >
        <img class="img" src="../assets/100.png"/>
        <h1 class="txt" v-if="!collapsed">{{companyName}}</h1>
      </div>
      <a-menu theme="light" mode="inline" :default-selected-keys="keys" >
         <template  v-for="item in Menus">
           <a-sub-menu v-if="item.children && !item.meta.hidden" :key="item.path">
              <span slot="title"><a-icon :type="item.meta.icon" /><span>{{item.meta.title}}</span></span>
              <a-menu-item :key="item.path+subItem.path" v-for="subItem in item.children">
                <router-link v-if="subItem.path=='/'" :to="item.path">
                  <a-icon :type="subItem.meta.icon" />
                  <span>{{subItem.meta.title}}</span>
                </router-link>
                <router-link v-else :to="`${item.path}/${subItem.path}`">
                  <a-icon :type="subItem.meta.icon" />
                  <span>{{subItem.meta.title}}</span>
                </router-link>
              </a-menu-item>
           </a-sub-menu>
           <a-menu-item :key="item.path" v-else >
             <router-link :to="item.path">
               <a-icon :type="item.meta.icon" />
                <span>{{item.meta.title}}</span>
             </router-link>
            </a-menu-item>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout id="components-layout-demo-custom-trigger-2">
      <a-layout-header style="background: #fff; padding: 0;display:flex;justify-content: space-between;align-items:center">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <div class="right-menu">
          <div v-if="total>0&&current>1">
            <a-icon type="left-circle" style="font-size:20px;color:#2593FC;" @click="prevClick" />
          </div>
          <div class="entrust-view">
            <a-tooltip placement="top" :title="item.username" v-for="(item,index) in clientList" :key="index">
              <div class="item" @click="openView(item)">
                <a-badge :count="countObj[item.user_id]">
                  <a-avatar :src="item.avatar" />
                </a-badge>
              </div>
            </a-tooltip>
          </div>
          <div v-if="total>0 && current*pageSize<total" style="margin-right:20px;padding-top:10px">
            <a-icon type="right-circle" style="font-size:20px;color:#2593FC;" @click="nextClick"/>
          </div>
          <a-dropdown>
            <div class="ant-dropdown-link login-user">
              <div class="user-img">{{img_name}}</div>
              <div class="text">{{clientId}}</div>
            </div>
            <a-menu slot="overlay">
              <a-menu-item key="0" @click="handleUpdate">
                修改个人信息
              </a-menu-item>
              <a-menu-item key="1" @click="handleExit">
                退出登录
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content
        :style="{ margin: '16px', padding: '24px', background: '#fff', height: '100%',}"
      >
        <router-view/>
      </a-layout-content>
    </a-layout>
  </a-layout>
  <a-modal v-model="visible" title="修改个人信息" @ok="handleSubmit">
    <a-form  :form="updateData">
      <a-form-item 
          label="账号" 
          :label-col="{span:6}" 
          :wrapper-col="{ span: 14 }"
          >
          <a-input
              v-model="updateData.account"
              placeholder="请输入账号"
              :disabled="true"
          />
      </a-form-item>
      <a-form-item 
          label="密码"
          :label-col="{span:6}" 
          :wrapper-col="{ span: 14 }"
          >
          <a-input
              v-model="updateData.password"
              placeholder="请输入密码"
          />
      </a-form-item>
    </a-form>
  </a-modal>
  </div>
</template>
<script>
import {Menus} from '@/router'
import {
    mapState
  } from 'vuex'
export default {
  data(){
    return{
       collapsed: false,
       visible:false,
       Menus,
       keys:[],
       clientList:[],
       openList:{},
       pageSize:10,
       current:1,
       total:0,
       clientId:'',
       updateData:{},
       countObj:{},

    }
  },
  computed: {
      ...mapState(['mqttInit','getAgain','company','user','companyName']),
      img_name(){
        if(this.user.account.indexOf('_')!=-1){
          return this.user.account.split('_')[1].substring(0,1)
        }else{
          return this.user.account.substring(0,1)
        }
      }
  },
  watch:{
      mqttInit:{
          handler(val){
              if(val){
                  this.getClientList()
                  this.receiveListener()
              }
          },
          immediate:true
      },
      getAgain:{
          handler(val){
              if(val){
                this.current = 1
                this.getClientList()
                this.$store.commit('syncUpdateAgain',false)
              }
          },
          immediate:true
      },

  },
  mounted(){
   // console.log(Menus)
    // console.log(this.$route)
    this.clientId = lockr.get('clientId')
    let list = this.$route.path.split('/')
    list = list.filter(item=>item)
    if(list.length===1){
      this.keys.push('/'+list[0],'/'+list[0]+'/')
    }else{
      this.keys.push('/'+list[0])
      this.keys.push('/'+list[0]+list[1])
    }
    // console.log(this.keys)
    
  },
  methods:{
    getClientList(){
      let robots = lockr.get('robots')
      let payload = {
        msgId:'getUserlist',
        data: {
          pageSize:this.pageSize,
          pageNum:this.current,
          robot_status:'2',
          robots
        },
      };
      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/weUser/searchUserByPage",
          payload,
        },
        (topic, data) => {
          if(data.code===200){
            this.clientList = data.data.res;
            this.clientList.forEach(item=>{
              this.countObj[item.user_id] = 0
            })
            this.total = data.data.count;
          }
        },
        this
      );
    },
    handleUpdate(){
      this.updateData = this.user
      this.visible = true
    },
    handleSubmit(){
      let payload = {
          msgId:'updateUser',
          data:this.updateData
      }
      this.$mqtt.doPublish(
          {
          pubTopic: "scrm/auth/updateUser",
          payload,
          },
          (topic, data) => {
              if(data.success){
                  this.$message.success('修改成功,请重新登录')
                  this.visible = false
                  setTimeout(() => {
                    location.reload();
                  }, 1000);
              }
          },
          this
      );
    },
    receiveListener(){
      this.$mqtt.addListener(
        {
          topic: "receive",
          delete: false,
        },
        (topic, data) => {
          let chat = this.openList[data.receiver]
          if(chat && chat.show){
            return
          }
          let keys = Object.keys(this.countObj)
          if(keys.length>0 && keys.indexOf(data.receiver)!==-1){
            this.$set(this.countObj, data.receiver,this.countObj[data.receiver]+1)
            this.$forceUpdate()
          }
        },
        this
      );
    },
    handleExit(){
      location.reload();
    },
    openView(item){
      this.$set(this.countObj, item.user_id,0)
      let chat = this.$chat({client:this.$mqtt.mqtt,clientId:item._id,company:item.app})
      this.openList[item.user_id] = chat
       this.$forceUpdate()
    },
    nextClick(){
      this.current ++
      this.getClientList()
    },
    prevClick(){
      this.current --
      this.getClientList()
    }
  }
}
</script>
<style lang="scss">
  .ant-badge-count{
    box-shadow:none !important;
  }

  .ant-menu-root.ant-menu-inline{
    height: calc(100% - 64px);
    overflow: scroll;
  }

  .ant-menu-root::-webkit-scrollbar {
      display: none;
  }
#components-layout-demo-custom-trigger,#components-layout-demo-custom-trigger-2{
  height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  display: flex;
  align-items:center;
  .img{
    width: auto;
    height: 32px;
    margin-right: 10px;
  }
   .txt{
    margin: 0px;
    animation-name: ani;
    animation-duration: 3s;
  }
}

@keyframes  ani{
      0%{  opacity:0; }
      100%{ opacity:1; }
}
.ant-layout-content {
    overflow: scroll;
}
.right-menu{
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .login-user{
    max-width: 100px;
    display: flex;
    align-items: center;
    
    .text{
      width: calc(100% - 37px);
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
    }
    .user-img{
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ddd;
      font-size: 18px;
      font-weight: bold;
      margin-right: 5px;
    }
  }
  .entrust-view{
    // background: #1890ff;
    max-width: 500px;
    // height: 40px;
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
    overflow-x: scroll;
    .item{
      flex-shrink: 0;
      text-align: center;
      line-height: 50px;
      width: 50px;
      height: 50px;
    }
  }
  .entrust-view::-webkit-scrollbar{display:none;}
}
</style>
