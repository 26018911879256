<template>
<div id="main">
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view/>
    </a-config-provider>
  </div>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  data(){
    return{
      locale: zhCN,
    }
  },
  
  created() {
    
    window.addEventListener('online', ()=>{console.log('online')})
    window.addEventListener('offline', ()=>{console.log('offline')})  
  }
}
</script>
<style lang="scss">
#app,#main,.page-main-div{
  height: 100%;
}
#main{
  position: relative;
}
</style>
