<template>
    <a-select
        v-model="robots"
        placeholder="请选择"
        @select="handleSelected"
    >
        <a-select-option v-for="d in robotList" :key="d.user_id" :value="d.user_id">
        {{ d.username }}
        </a-select-option>
    </a-select>
</template>
<script>
export default {
    name:'robot-selected',
    props:{
        init:Boolean
    },
    data(){
        return{
            robotList:[],
            robots:''
        }
    },
    watch:{
        init(val){
            if(val){
                this.robots = ''
                this.handleSearchRobots()
            }
        }
    },
    mounted(){
        this.handleSearchRobots()
    },
    methods:{
         handleSearchRobots(){
            let payload = {
                msgId:'searchUserByPage',
                data: {
                    pageSize:100,
                    pageNum:1,
                    robot_status: '2'
                },
            };
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/weUser/searchUserByPage",
                payload,
                },
                (topic, data) => {
                if(data.code===200){
                    this.robotList = data.data.res;
                }
                
                },
                this
            );
        },
        handleSelected(){
            // console.log(this.robots)
            this.$emit('handleSelected',this.robots,this.robotList.find(item=>item.user_id===this.robots))
        }
    }
}
</script>
<style lang="scss" scoped>

</style>