<template>
     <div class="page">
        <a-form layout="inline" style="margin-bottom:20px">
            
            <a-form-item class="dep" label="群名称:">
                <a-input v-model="searchMultForm.nickname" placeholder="群名称"></a-input>
            </a-form-item>
            <a-form-item class="dep" label="群类型:">
                <a-select v-model="searchMultForm.room_ext_type"
                        placeholder="请选择"
                        style="width: 120px">
                    <a-select-option value="all">全部</a-select-option>
                    <a-select-option value="0">内部</a-select-option>
                    <a-select-option value="2">外部</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" icon="search" @click="handleClickSearchMult">
                    搜索
                </a-button>    
            </a-form-item>
        </a-form>
        <a-table
            :columns="multColumns" 
            :data-source="multData"
            :row-key="record => record.conversation_id"
            size="small"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange ,getCheckboxProps:getCheckboxProps}"
            :bordered="false"
            :pagination="multPagination"
            @change="handleTableChange"
        >
            <div slot="nickname" slot-scope="text">
                {{text||'临时群'}}
            </div>
            <template slot="label" slot-scope="arr">
                <a-tag v-for="item in arr" :key="item" color="blue" >{{item}}</a-tag>
            </template>
        </a-table>
    </div>
</template>
<script>
const multColumns=[
    {
      title: "群名称",
      dataIndex: "nickname",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'nickname' },
    },
    {
      title: "群主",
      dataIndex: "create_user_name",
      align: "center",
      ellipsis: true,
    },
    
    {
      title: "群标签",
      dataIndex: "label_name",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'label' },
    },
]
export default {
    name:'mult-choose',
    props:{
        init:Boolean,
        selectedValue:Array
    },
    data(){
        return{
            multColumns,
            searchMultForm:{
                nickname:'',
                room_ext_type:''
            },
            multPagination: {
                total: 1,
                current: 1,
                pageSize: 10, //每页中显示10条数据
                showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
            },
            multData:[],
            selectdList:[],
            selectedRowKeys:[],
        }
    },
    watch:{
        init(val){
            if(val){
                this.searchMultForm = {
                    nickname:'',
                    room_ext_type:''
                }
                this.selectdList = []
                this.selectedRowKeys = []
                this.handleClickSearchMult()
            }
        }
    },
    mounted(){
        this.handleClickSearchMult()
    },
    methods:{
        handleTableChange(pagination){
            this.multPagination = pagination
            this.handleMultSearch()
        },
         handleClickSearchMult(){
            this.multPagination.current = 1
            this.handleMultSearch()
        },
        handleMultSearch(){  
            let {nickname,room_ext_type} = this.searchMultForm
             let payload = {
                msgId:'searchGroupByPage',
                data:{
                    nickname,
                    live:false,
                    pageSize:this.multPagination.pageSize,
                    pageNum:this.multPagination.current
                }
            }
            if(room_ext_type!=='all'){
                payload.data.room_ext_type = room_ext_type
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxGroupService/searchGroupByPage",
                payload,
                },
                (topic, data) => {
                    this.multData = data.data.res
                    this.multPagination.total = data.data.count
                },
                this
            );
        },
        onSelectChange(selectedRowKeys){
            // console.log(selectedRowKeys.length,selectedRows.length)
            this.selectedRowKeys = selectedRowKeys;
            // let empty = this.selectdList.concat(selectedRows)
            // let obj = {}
            // empty = empty.reduce((cur,next)=>{
            //     obj[next.userid] ? "" : obj[next.userid] = true && cur.push(next);
            //     return cur;
            // },[])
            
            // empty = empty.filter(item=>{
            //     return this.selectedRowKeys.includes(item.userid)
            // })
            // this.selectdList = empty
            this.$emit('handleSelected',selectedRowKeys)
        
        },
        getCheckboxProps(record){
            return{props: {
                // disabled: record.status === 1, // Column configuration not to be checked
                status: record.status,
            }}
        },
    }
}
</script>
<style lang="scss" scoped>
.page{
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
}
</style>